import { basicSplide } from '../lib/splide';
import AOS from 'aos';
import ZipLocator from '../components/ZipLocator';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

export default {
  init() {
    // JavaScript to be fired on all pages

    // Animate on Scroll Library https://michalsnik.github.io/aos/
    AOS.init();

    // Splide https://splidejs.com/
    basicSplide();

    // Initiate Fancybox
    $('[data-fancybox], [href="#ezsignup"]').fancybox();

    if ($('.form-zip-locator').length) {
      const locator = new ZipLocator($('.form-zip-locator'));
      locator.init();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
