export default class ZipLocator {
  constructor(form)
  {
    if (!$(form).length) return;

    this._form = $(form);
  }

  get val()
  {
    return this._form.find('#locator-input-zip').val()
  }

  init()
  {
    this._form.on('submit', (e) => {
      e.preventDefault();
      this.onSubmit();
    });
  }

  onSubmit()
  {
    if (!this.val) return;

    console.log('onSubmit', this.val);

    if (['33431', '33071'].includes(this.val)) {
      window.location.href = '/locations/boca-raton-fl/';
      return;
    }

    $.fancybox.open($('#ezsignup')[0]);
  }
}
