import Splide from '@splidejs/splide';
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export const basicSplide = () => {

	// Splide Slides
	const citiesSplide       = document.getElementById( 'cities-splide' );
	const testimonialsSplide = document.getElementById( 'testimonials-splide' );
	//	const rentalSplide       = document.getElementById( 'rentals-splide' );

	if ( citiesSplide ) {
		var splideCities = new Splide( '#cities-splide', {
			type       : 'loop',
			pagination : false,
			perPage    : 4,
			perMove    : 1,
			gap        : '3em',
			arrows     : true,
			autoplay   : true,
			breakpoints: {
				//				992: {
				//					perPage: 4,
				//					gap    : '1rem',
				//				},
				768: {
					perPage: 3,
					gap    : '1rem',
				},
				390: {
					perPage: 2,
					gap    : '1rem',
				},
			},
		} );

		splideCities.mount();
	}

	if ( testimonialsSplide ) {
		var splideTestimonials = new Splide( '#testimonials-splide', {
			type      : 'fade',
			speed     : 400,
			pagination: false,
			perPage   : 1,
			perMove   : 1,
			arrows    : true,
			autoplay  : true,
		} );

		splideTestimonials.mount();
	}

	// Rentals Splide
	document.querySelectorAll( '.rentals-splide' ).forEach( carousel => new Splide( carousel, {
		type      : 'fade',
		speed     : 400,
		pagination: true,
		perPage   : 1,
		perMove   : 1,
		arrows    : false,
		autoplay  : true,
	} ).mount() );

};
